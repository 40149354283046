import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import QuoteMark from '../../../../Atoms/QuoteMark/QuoteMark';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import Carousel from '../../../../components/Carousel/Carousel';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import FindMore from '../../../../components/FindMore/FindMore';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import Seo from '../../../../components/Seo/Seo';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import { Button } from '../../../../Molecules/Button/Button';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import {
  removeHtmlTag,
  getBreadCrumb,
  getReOrderedCarouselSlides,
} from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const RoutePage = ({ data }) => {
  const intl = useIntl();
  const { processUrl, getImage } = useMedias();

  const detailsActivitiesData = data?.detailsActivitiesData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, detailsActivitiesData.path?.alias);
  const metaTags =  detailsActivitiesData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  const [allText, setAllText] = useState('');

  let globalText = '';

  const slider_settings = {
    className: 'center_dots',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 300,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };



  useEffect(() => {
    setAllText(
      removeHtmlTag(
        `${detailsActivitiesData.title != undefined &&
          detailsActivitiesData.title != null
          ? detailsActivitiesData.title
          : ''
        }.${detailsActivitiesData.body?.processed != undefined &&
          detailsActivitiesData.body?.processed != null
          ? detailsActivitiesData.body?.processed
          : ''
        }.${detailsActivitiesData.field_text_2?.processed != undefined &&
          detailsActivitiesData.field_text_2?.processed != null
          ? detailsActivitiesData.field_text_2?.processed
          : ''
        }.${globalText}`
      )
    );
  }, []);

  return (
    <Layout>
      <Seo
        title={detailsActivitiesData?.field_metatag?.title ? detailsActivitiesData?.field_metatag?.title : metaTitle}
        description={detailsActivitiesData?.field_metatag?.description ? detailsActivitiesData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'activities_inner_page')}>
        <PageBannerStyle
          visuel={getImage(
            imagesArray,
            detailsActivitiesData?.relationships?.field_image
              ?.drupal_internal__mid
          )}
          alt={detailsActivitiesData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            gparent: {
              title: breadCrumb?.grandParent?.name,
              url: breadCrumb?.grandParent?.link,
            },
            parentPage: {
              title: breadCrumb?.parent?.name,
              url: breadCrumb?.parent?.link,
            },
            currentPage: {
              title: breadCrumb?.current?.name,
              url: breadCrumb?.current?.link,
            },
            locale: detailsActivitiesData.langcode,
          }}
        >
          <div className="wrapper_page">
            {detailsActivitiesData.title && (
              <TitlePage
                color="color_white"
                title={detailsActivitiesData.title}
              />
            )}

            <div className="col_2_fix">
              <div className="col">
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailsActivitiesData.body?.processed,
                  }}
                ></div>
              </div>
              <div className="col">
                <div
                  dangerouslySetInnerHTML={{
                    __html: detailsActivitiesData.field_text_2?.processed,
                  }}
                ></div>
              </div>
            </div>
            {allText &&
              detailsActivitiesData.field_version_audio &&
              allText.length > 30 && (
                <AudioComponent
                  text={intl.formatMessage({
                    id: 'detailsActivities.button.listen.label',
                  })}
                  textToRead={allText}
                />
              )}
          </div>
        </PageBannerStyle>

        {detailsActivitiesData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__basic':
              globalText +=
                block.field_title?.processed + '.' + block?.body?.processed;
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_diverse_activities'
                  )}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    <TitleSection title={block.field_title?.processed} />

                    <TextVisuelTwoCol
                      orderInverse={
                        block.field_alignment === 'right' ? true : false
                      }
                      extremeRight={
                        block.field_alignment === 'right' ? true : false
                      }
                      extremeLeft={
                        block.field_alignment === 'left' ? true : false
                      }
                      alignLeft={false}
                      alignTop={false}
                      visuel={getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )}
                      alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                      text={block?.body?.processed}
                    />
                    {
                      block?.relationships?.field_sites_web?.length > 0 &&
                      <FindMore
                        componentData={block?.relationships?.field_sites_web}
                        alignment={block.field_alignment}
                        page_name="nos_activites"
                        page_type="groupe"
                        zone={removeHtmlTag(block.field_title?.processed)}
                        activity={detailsActivitiesData.title}
                      />
                    }
                  </div>
                </section>
              );
            case 'block_content__block_citation':
              globalText += block?.field_citation + '.';
              return (
                <section className={classNames('section_content')} key={i}>
                  <div className="quotemark_container">
                    <QuoteMark
                      lang={block.langcode}
                      size="m"
                      textWeight="m"
                      text={block?.field_citation}
                    />
                  </div>
                </section>
              );

            case 'block_content__block_slider_nos_activites':
              globalText +=
                block.field_title?.processed +
                '.' +
                block.field_subtitle?.processed;
              let slides = [];
              block.relationships?.field_nos_activites?.forEach((slideType) => {
                slides.push({
                  image: getImage(
                    imagesArray,
                    slideType?.relationships?.field_image?.drupal_internal__mid
                  ),
                  alt: slideType.relationships?.field_image?.field_media_image?.alt || '',
                  info: {
                    text: slideType.title,
                    link:
                      '/' + slideType.path?.langcode + slideType.path?.alias,
                    link_label: block.field_link_title,
                  },
                });
              });

              return (
                <section
                  className={classNames(
                    'section_content',
                    'section_our_realisation'
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top="left"
                    color="catskill_white"
                    bottomInfinite={true}
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        subtitle={block.field_subtitle?.processed}
                        type="arrow"
                      />
                    </div>

                    {slides.length > 0 && (
                      <div
                        className={classNames(
                          'wrapper_page_xs',
                          'only_desktop'
                        )}
                      >
                        <Carousel
                          slides={getReOrderedCarouselSlides(slides)}
                          contentName={block.field_title?.processed}
                          pageType="groupe"
                          pageName="nos_activites_n-1"
                        />
                      </div>
                    )}

                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_settings}>
                        {slides.map((slide, j) => (
                          <div key={j}>
                            <div className="only_mobile_tablet">
                              <img
                                src={processUrl(slide.image?.image_style_uri?.gatsby_medium)}
                                alt={slide.alt}
                                style={{
                                  width: '100%',
                                  height: '120vw',
                                  maxHeight: '510px',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                            <div className="info_bleu_box">
                              <p>{slide.info?.text}</p>
                              {slide.info?.link && (
                                <Button
                                  primary={true}
                                  label={slide.info?.link_label}
                                  link={slide.info?.link}

                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </SliderComponent>
                    </div>
                  </ShapeBackground>
                </section>
              );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query DetailsActivitiesTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    detailsActivitiesData: allNodeActivites(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }

            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  body {
                    processed
                  }
                  field_alignment
                  field_title {
                    processed
                  }
                  field_no_padding
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_sites_web {
                      field_link {
                        url
                      }
                      field_text {
                        processed
                      }
                      field_subtitle
                    }
                  }
                }
                ... on block_content__block_citation {
                  field_citation
                  langcode
                }
                ... on block_content__block_slider_nos_activites {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom
                  field_position_bordure_haut
                  field_link_title
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__activites {
                          path {
                            alias
                            langcode
                          }
                          title
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                        ... on node__realisations {
                          path {
                            alias
                            langcode
                          }
                          title
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          field_text_2 {
            processed
          }
          body {
            processed
          }
          title
          langcode
          field_version_audio
          path {
            langcode
            alias
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }

    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default RoutePage;
